import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Form, withFormik} from "formik";
import {WrappedFormComponents} from "../../util/FormComponents/FormikWrappedComponents";
import {EmailHelpTextLabel, RegisterCheckboxLabels, StyledFormFieldDiv} from "../../util/FormComponents/CommonStyles";
import {Cta} from "@qc-modules/components";
import {submitSignUp} from "../../../api/register-api";
import Loading from "../../util/Loading";
import history from '../../../context/History';
import {SignUpErrorResponse} from "./SignUpResponse";
import {validateDomainsListForPartnerDomains, ValidationFunctions} from "../../util/validation/ValidationFunctions";
import {MessageWrapper} from "../../util/validation/ValidationStyles";
import WarningModal from "../response/WarningModal";
import {WWW_APP} from "../../util/helper";
import {trackCtaEvent} from "../../../utils/Utils";
import usePreviousPage from "../../../hooks/usePreviousPage";


const SignUpBasicForm = ({
                             isSubmitting,
                             ...props
                         }) => {

    const [showWarningModal, setShowWarningModal] = useState(false);
    const previousPage = usePreviousPage();

    useEffect(() => {
        // trackPageLoadEvent('signup','SignUp', previousPage, {}, {})
        window.pageChange('signup','SignUp', previousPage, {}, {})
        // trackFormEvent('form view', 'SignUp', 3)
    }, [previousPage]);

    const validateEmailDomainsCheck = (val) => {
        const {appData, domains} = props;

        if (!appData.allowsPublicDomains && val) {
            const validEmailForApp = validateDomainsListForPartnerDomains(val, domains);
            if (!validEmailForApp) {
                setShowWarningModal(true);
            }
        }
    };
    const closeModal = (e, continueForWww) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShowWarningModal(false);
        if (continueForWww) {
            props.values.app = WWW_APP;
            props.values.targetUrl = ''
        } else {
            props.resetForm()
        }

    };

    return (
        <>
            {showWarningModal &&
                <WarningModal closeModal={closeModal} warningText={props.appData.publicDomainWarning}/>}
            {isSubmitting && <Loading/>}
            <Form>
                <StyledFormFieldDiv>
                    <Field name="email"
                           id="email"
                           placeholder="Email"
                           label='Email*'
                           labelPosition='placeholder'
                           bottomLabel={<EmailHelpTextLabel/>}
                           type='text'
                           width={1}
                           component={WrappedFormComponents.InputWrapper}
                           validate={(value) => ValidationFunctions.validateEmail(value, props.app)}
                           onBlur={e => {
                               // call the built-in handleBur
                               props.handleBlur(e);
                               validateEmailDomainsCheck(e.currentTarget.value);
                           }}
                    />
                    <MessageWrapper><ErrorMessage name="email"/></MessageWrapper>
                </StyledFormFieldDiv>

                <StyledFormFieldDiv>
                    <Field as="checkbox"
                           name="termsOfService"
                           id="termsOfService"
                           component={WrappedFormComponents.CheckboxWrapper}
                           width={1}
                           validate={ValidationFunctions.validateTermsOfService}
                           label={
                               <RegisterCheckboxLabels>
                                   I agree to the <a href="https://www.qualcomm.com/site/terms-of-use"
                                                     target="_blank"
                                                     rel="noopener noreferrer">Qualcomm Terms of Use</a> and <a
                                   href="https://www.qualcomm.com/site/privacy"
                                   target="_blank"
                                   rel="noopener noreferrer">Privacy Policy</a>
                               </RegisterCheckboxLabels>
                           }
                    />
                    <MessageWrapper><ErrorMessage name="termsOfService"/></MessageWrapper>
                </StyledFormFieldDiv>

                <StyledFormFieldDiv>
                    <Field as="checkbox"
                           name="optIn"
                           id="optIn"
                           component={WrappedFormComponents.CheckboxWrapper}
                           width={1}
                           label={
                               <RegisterCheckboxLabels>Keep me informed about Qualcomm products and solutions with
                                   emails from Qualcomm Technologies, Inc.
                               </RegisterCheckboxLabels>
                           }
                    />

                </StyledFormFieldDiv>

                <Cta version="secondary"
                     data-testid='submit-signup-button'
                     role='button'
                     disabled={isSubmitting}>Continue</Cta>
            </Form>
        </>
    )
};

const SignUpForm = withFormik({
    enableReinitialize: true,
    mapPropsToValues: (props) => ({
        email: props.email || '',
        termsOfService: props.termsOfService || false,
        optIn: props.optIn || false,
        targetUrl: props.targetUrl || '',
        app: props.app || '',
        domains: props.domains || ''
    }),
    handleSubmit(values, {resetForm, setSubmitting}) {
        trackCtaEvent('/signup', 'Sign up')
        submitSignUp(values)
            .then(() => {
                // trackFormEvent('form submit', 'SignUp', 'Continue', '/signup/success')
                resetForm();
                history.push('/signup/success', {...values});
            })
            .catch(err => {
                // trackFormEvent('form submit error', 'SignUp', err.message)
                history.push('/signup/error', {...SignUpErrorResponse});

            })
            .finally(() => {
                setSubmitting(false);
            });
    }
})(SignUpBasicForm);

export default SignUpForm;