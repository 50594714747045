import React from 'react';
import {Form, withFormik} from "formik";
import {submitSignUp} from "../../../api/register-api";
import {Cta} from "@qc-modules/components";
import Loading from "../../util/Loading";
import history from '../../../context/History';
import {SignUpErrorResponse} from "../signup/SignUpResponse";


const TokenRequestBasicForm = ({isSubmitting}) => {
    return (
        <>
            {isSubmitting && <Loading/>}

            <Form>
                <Cta version="secondary"
                     data-testid='submit-signup-button'
                     role='button'
                     disabled={isSubmitting}>Submit</Cta>
            </Form>
        </>
    )
};

const TokenRequestForm = withFormik({
    mapPropsToValues: (props) => ({
        email: props.email || '',
        targetUrl: props.targetUrl || '',
        app: props.app || ''
    }),
    handleSubmit(values, {resetForm, setSubmitting}) {
        submitSignUp(values)
            .then(() => {
                resetForm();
                setSubmitting(false);
                history.push('/signup/success', {...values});
            })
            .catch(err => {
                setSubmitting(false);
                history.push('/signup/error', {...SignUpErrorResponse});
            });
    }
})(TokenRequestBasicForm);

export default TokenRequestForm;